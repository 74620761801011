import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
    <Layout>
        <SEO title="About" />
        <div className="post p2 p-responsive wrap" role="main">
            <div className="measure">
                <div className="post">
                    <header className="post-header">
                        <h1 className="h2">About Me</h1>
                    </header>

                    <article className="post-content">
                        <p>
                            I was bought up in{" "}
                            <a
                                href="https://en.wikipedia.org/wiki/St_Asaph"
                                target="_blank"
                                rel="noopener noreferrer">
                                St Asaph
                            </a>
                            , Wales, a small town where everyone knows everyone, and nothing much
                            ever changes. From there I ventured across the border, studied music for
                            a while, then headed to the{" "}
                            <a
                                href="https://en.wikipedia.org/wiki/University_of_Warwick"
                                target="_blank"
                                rel="noopener noreferrer">
                                Univeristy of Warwick
                            </a>{" "}
                            and became a “Master of Civil Engineering” <em>apparently</em>. I used
                            this new-found skill to earn some money designing bridges and gantries,
                            rock climbing in most of my free time.
                        </p>

                        <p>
                            Climbing took me all over the UK, continental Europe and China. After
                            the China trip I didn’t really want to go back to the nine-to-five, so I
                            didn’t. I got a one-way ticket to China, and that was that.
                        </p>

                        <p>
                            I decided to follow my passion for all things techy and started
                            designing websites. I love the pace of the industry, it’s a challenge to
                            keep up-to-date but a challenge I relish.
                        </p>
                    </article>
                </div>
            </div>
        </div>
    </Layout>
);

export default IndexPage;
